import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import '@mdi/font/css/materialdesignicons.css'
import './registerServiceWorker'
import { store } from './store'
import { auth, db } from "./firebase/firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { signInWithRedirect, signInWithPopup, OAuthProvider } from "firebase/auth";// signInWithPopup 
import { collection, getDocs, query, where } from "firebase/firestore";//getFirestore,
import TreeView from "vue-json-tree-view"
import * as VueGoogleMaps from 'vue2-google-maps';
import VueMeta from 'vue-meta'
import VueGeolocation from 'vue-browser-geolocation'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(TreeView)
Vue.use(VueMeta)
Vue.use(VueGeolocation)
Vue.use(VueGoogleMaps, {
	load: {
			key: process.env.VUE_APP_GOOGLE_MAPPS_API_KEY,
			libraries: 'places', // 'places,drawing,visualization'
			installComponents: true,
	},
})

/*
const AwaitForFirebase = () => {
	return new Promise((resolve) => {
		const listener = auth.onAuthStateChanged(() => {
			listener()
			resolve()
		})
	})
}*/

auth.onAuthStateChanged(async (user) => {
	const _useragent = navigator.userAgent
	console.log("User-agent Browser : " + _useragent) //DSB-Co6ejP0fsu-bnCN8m1MVY
	if (_useragent === "DSB-Co6ejP0fsu-bnCN8m1MVY") {
		signInWithEmailAndPassword(auth, _useragent+'@new.de', _useragent)
			.then((userCredential) => {
				// Signed in 
				user = userCredential.user;
				store.commit("setUserPersonnelNr", _useragent);
				store.commit("setUserLogin", user)
				store.dispatch("attachEmployee")
				store.commit("setIsAuthenticating", true)
				store.commit("setHinweis", "Angemeldet!", false)
			})
			.catch((error) => {
				const errorCode = error.code;
				if (errorCode === 'auth/user-not-found') {
					console.log("USER anlegen")
					createUserWithEmailAndPassword(auth, _useragent+'@new.de', _useragent)
					.then((userCredential) => {
						updateProfile(userCredential.user, {
							displayName: "ZBL",
							emailVerified: true
						}).then(function() {
							user = userCredential.user
							store.commit("setUserPersonnelNr", _useragent)
							store.commit("setUserLogin", user)
							store.dispatch("attachEmployee")
							store.commit("setIsAuthenticating", true)
							store.commit("setHinweis", "Angemeldet!", false)
						}, function(error) {
							console.log("ERROR: ", error)
							store.commit("setIsAuthenticating", false)
							store.commit("setHinweis", "Nicht angemeldet!!", false)
							store.commit("setUserLogin", null)
							store.commit("setUserClaims", null)
							const provider = new OAuthProvider('microsoft.com')
							provider.setCustomParameters({
								tenant: '494aa4dd-1c7b-453f-88b1-513bbe6982d0'
							});
							signInWithRedirect(auth, provider)
						});   
					})
					.catch((error) => {
						console.log("ERROR: ", error)
						store.commit("setIsAuthenticating", false)
						store.commit("setHinweis", "Nicht angemeldet!!", false)
						store.commit("setUserLogin", null)
						store.commit("setUserClaims", null)
						const provider = new OAuthProvider('microsoft.com')
						provider.setCustomParameters({
							tenant: '494aa4dd-1c7b-453f-88b1-513bbe6982d0'
						});
						signInWithRedirect(auth, provider)
					});
				}
			});
	} else if (_useragent === "DSB" || _useragent === "MUM") {
		signInWithEmailAndPassword(auth, _useragent+'@new.de', _useragent+"-Co6ejP0fsu-bnCN8m1MVY")
			.then((userCredential) => {
				// Signed in 
				user = userCredential.user;
				store.commit("setUserPersonnelNr", _useragent);
				store.commit("setUserLogin", user)
				store.dispatch("attachEmployee")
				store.commit("setIsAuthenticating", true)
				if (_useragent === "MUM") {
					store.commit("setwithMenue", false)
				}
				store.commit("setHinweis", "Angemeldet!", false)
			})
			.catch((error) => {
				const errorCode = error.code;
				if (errorCode === 'auth/user-not-found') {
					console.log("USER anlegen")
					createUserWithEmailAndPassword(auth, _useragent+'@new.de', _useragent+"-Co6ejP0fsu-bnCN8m1MVY")
					.then((userCredential) => {
						updateProfile(userCredential.user, {
							displayName: _useragent,
							emailVerified: true
						}).then(function() {
							user = userCredential.user
							store.commit("setUserPersonnelNr", _useragent)
							store.commit("setUserLogin", user)
							store.dispatch("attachEmployee")
							store.commit("setIsAuthenticating", true)
							if (_useragent === "MUM") {
								store.commit("setwithMenue", false)
							}
							store.commit("setHinweis", "Angemeldet!", false)
						}, function(error) {
							console.log("ERROR: ", error)
							store.commit("setIsAuthenticating", false)
							store.commit("setHinweis", "Nicht angemeldet!!", false)
							store.commit("setUserLogin", null)
							store.commit("setUserClaims", null)
							const provider = new OAuthProvider('microsoft.com')
							provider.setCustomParameters({
								tenant: '494aa4dd-1c7b-453f-88b1-513bbe6982d0'
							});
							signInWithRedirect(auth, provider)
						});   
					})
					.catch((error) => {
						console.log("ERROR: ", error)
						store.commit("setIsAuthenticating", false)
						store.commit("setHinweis", "Nicht angemeldet!!", false)
						store.commit("setUserLogin", null)
						store.commit("setUserClaims", null)
						const provider = new OAuthProvider('microsoft.com')
						provider.setCustomParameters({
							tenant: '494aa4dd-1c7b-453f-88b1-513bbe6982d0'
						});
						signInWithRedirect(auth, provider)
					});
				}
			});
	} else {
		if (user) {
			if (user.photoURL == null) {
				//			const q = query(collection(firestore, "employees"), where("adaccount", "==", user.email.toLowerCase()));
				const q = query(collection(db, "employees"), where("adaccount", "==", user.email.toLowerCase()));
				const employeeSnap = await getDocs(q);
				employeeSnap.forEach((doc) => {
					const _u = doc.data();
					if (_u.photoURL != "") {
						store.commit("setUserPhotoURL", _u.photoURL);
					}
					store.commit("setUserPersonnelNr", _u.persid);
				});
				//await AwaitForFirebase()
			} else {
				store.commit("setUserPhotoURL", user.photoURL);
			}
			store.dispatch("attachEmployee");
			store.commit("setUserLogin", user);
			store.commit("setIsAuthenticating", true);
			store.commit("setHinweis", "Angemeldet!", false);
		} else {
			store.commit("setIsAuthenticating", false);
			store.commit("setHinweis", "Nicht angemeldet!!", false);
			store.commit("setUserLogin", null);
			store.commit("setUserClaims", null);
			const provider = new OAuthProvider('microsoft.com');
			provider.setCustomParameters({
				tenant: '494aa4dd-1c7b-453f-88b1-513bbe6982d0'
			});
			signInWithRedirect(auth, provider)
		}
	}
});

Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyCKSO5ZhC5uAOp2ZBb-nyzTTkRkiINROs8',
		libraries: 'places', // This is required if you use the Autocomplete plugin
		// OR: libraries: 'places,drawing'
		// OR: libraries: 'places,drawing,visualization'
		// (as you require)

		//// If you want to set the version, you can do so:
		// v: '3.26',
	}
})

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
